.banking-sec{
    margin-top: 85px;
    font-family: 'Gilroy', sans-serif!important;   
}

.banking-header .back-icon span{
    font-size: 2em;
    font-weight: 600;
    padding-left: 0.6em;
}

.banking-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom:1px solid #eeeeee;
    padding-bottom: 1em;
}

.banking-header .back-icon {
    display: flex;
    align-items: center;
}

.banking-sec .banking-body .banking-personal-form .form-label {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: var(--quaternary-color);
    padding: 0 6px;
    margin: 0;
}

.banking-sec .banking-body .banking-personal-form .form-label span{
    font-weight: 400;
    text-transform: lowercase;
    margin-left: 3px;
}

.banking-sec .banking-body h2{
    font-size: 16px;
    font-weight: 500;
    vertical-align: middle;
    text-transform: uppercase;
    color: var(--quaternary-color);
}

.banking-sec .banking-body h4{
    font-size: 14px;
    font-weight: 500;
    vertical-align: middle;
    margin-bottom: 15px;
}

.banking-sec .banking-body .banking-personal-form{
    padding:2em 0;
}
.banking-sec .banking-body .banking-personal-form .custom-select {
    border:0;
    border-bottom:1px solid #ced4da!important;
    font-size: 13px;
    font-weight: 500;
}

.banking-sec .banking-body .banking-personal-form .custom-select:focus {
    box-shadow: none!important;
}
.banking-sec .banking-body .banking-personal-form .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.banking-sec .banking-body .banking-personal-form .btn-upload {
    padding: 9px 16px;
    font-weight: 500;
    color: var(--primary-color);
    border: 1px solid #e2ecf8;
    font-size: 14px;
    background: transparent;
    border-radius: 1000px;
    margin-top: 1em;
}

.banking-sec .banking-body .banking-personal-form .upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.banking-sec .banking-body .banking-personal-form .custom-control-label::before{
    top: 0.5px!important;
    left: -1.68rem!important;
    display: block;
    width: 2rem!important;
    height: 2rem!important;
}

.banking-sec .banking-body .banking-personal-form .custom-control-label {
    font-size: 1.4em;
    width: 100%;
    padding-left: 1em;
}

.banking-sec .banking-body .banking-personal-form .form-text {
    padding: 1px 16px 0;
    font-size: 12px;
    color: rgba(138,150,163,.75);
    margin: 0;
    min-height: 17px;
}



