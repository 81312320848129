.ecom-single-product-sec .carousel .thumbs{
    padding-left: 0;
    margin-bottom: 0;
 }

 .ecom-single-product-sec .carousel .thumbs-wrapper{
   margin-left: 0;
   margin-bottom: 0;
 }

 .ecom-single-product-sec .carousel .control-dots{
   display: none;
 }

 .ecom-single-product-sec .carousel .slide{
   margin-bottom: 0!important;
 }

 .ecom-single-product-sec .carousel .slide .quick-view-modal-img{
     height: 40em;
     object-fit: cover;
 }

 .ecom-single-product-sec{
     margin-top: 40px;
     padding-bottom: 4em;
 }

 .add-product-sec{
   margin-top: 80px;
   padding-bottom: 4em;
 }

 .add-product-sec .form-control{
  background-color: #ffffff;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%)!important;
  border-radius: 5px!important;
  padding-left: 1em!important;
  font-weight: 500;
  border: 0!important;
}

.add-product-sec .custom-select{
  height: 40px;
  font-size: 1.4em!important;
  background-color: #ffffff;
  border: 0!important;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%)!important;
  padding-left: 1.5em;
  color:#777;
}

.add-product-sec .form-control::placeholder{
  font-size: 14px;
  font-weight: 400;
}

.add-product-sec label{
  font-size: 1.5em;
  font-weight:500;
  color: #212529;
}

.add-product-form-sec{
 margin-top: 2em;
}

.add-product-form{
  margin-top: 4em;
}

.add-product-upload-file-sec .add-product-btn {
  background: var(--primary-color)!important;
  border-color: var(--primary-color)!important;
  padding: 10px 30px;
  border-radius: 1000px;
  text-align: center;
  color: var(--secondary-color);
  font-weight: 500;
  font-size: 1.5em;
  cursor: pointer;
  margin-top: 1em;
}

.add-product-upload-file-sec .add-product-btn:hover,
.add-product-upload-file-sec .add-product-btn:focus,
.add-product-upload-file-sec .add-product-btn:active {
  background: var(--primary-color)!important;
  border-color: var(--primary-color)!important;
  color: #000000!important;
}

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}
.uploader label {
  float: left;
  clear: both;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background-color: var(--secondary-color);
  border: 1px solid rgba(0, 0, 0, 0.05)!important;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%)!important;
  padding-left: 1.5em;
  color: #777;
  border-radius: 7px;
  /* border: 3px solid #eee; */
  transition: all 0.2s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 9.5em;
  display: flex;
  align-items: center;
  justify-content: center;   
  margin-bottom: 1em; 
}

.add-product-upload-file-sec{
  margin-top: 2em;
}

.uploader label.hover #start i.fa {
  transform: scale(0.8);
  opacity: 0.3;
}

.uploader #start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all 0.2s ease-in-out;
}

.uploader #file-image {
  display: inline;
  margin: 0 auto 0.5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

.uploader input[type=file] {
  display: none;
}

.add-product-btn-sec{
  display:flex;
  align-items: center;
  justify-content: flex-end;
}

/*Product List CSS*/

.product-list-sec {
  margin-top: 4em;
  padding-bottom: 4em;
}

.product-list-sec .table thead.thead-primary {
  background: var(--primary-color);
}

.product-list-sec .table thead th {
  border: none;
  padding: 20px;
  font-size: 13px;
  font-weight: 600;
  color:#000000;
}

.product-list-sec .table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.product-list-sec .table tbody tr {
  margin-bottom: 10px;
}

.product-list-sec .alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.product-list-sec .table tbody th, .product-list-sec .table tbody td {
  border: none;
  padding: 20px;
  font-size: 14px;
  background: #fff;
  border-bottom: 4px solid #f8f9fd;
  vertical-align: middle;
  width: 13%;
}

.product-list-sec .table tbody td .img {
  width: 100px;
  height: 80px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.product-list-sec .table tbody td .email{
  width: 60%;
}

.product-list-sec .table tbody td .email span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
  width: 15em;
}

.product-list-sec .table tbody td .email span:last-child {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.3);
}

.product-list-sec .table-wrap{
  margin-top: 4em;
}

.product-list-sec .view {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  background: var(--secondary-color) !important;
  border:0!important;
}

.product-list-sec .view i {
  font-size: 1.4em;
  color: #000000;
  opacity: .5;
}

.product-list-sec .view:hover i{
  opacity: .75;
}

.product-list-sec .edit {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  background: var(--secondary-color) !important;
  border:0!important;
}

.product-list-sec .edit i {
  font-size: 1.4em;
  color: #000000;
  opacity: .5;
}

.product-list-sec .edit:hover i{
  opacity: .75;
}

.custom_tooltip{
  font-size: 1rem;
}
