.live-streaming-post-sec{
    margin-top: 20px;
}

.live-streaming-post-box{
    width:100%;
    /* padding: 2em;
    box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
    border-radius: 15px; */
}

.live-streaming-post-img{
    width: 100%;
    height: 30em;
    object-fit: cover;
}

.live-streaming-post-card .live-streaming-post-user-info .live-streaming-post-user-img{
    width: 5em;
    height: 5em;
    border-radius: 50%;
    border:2px solid #ca3939;
    background-color: #fff;
    padding: 2px;
    object-fit:cover;
}

.live-streaming-post-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding:1.5em;
}

.live-streaming-post-user-info{
    display: flex;
    align-items: center;
}

.live-streaming-post-user-details{
    padding-left: 1.5em;
}

.live-streaming-post-user-details h4 {
    font-weight: 600;
    line-height: 1.5;
    color: #6f6f6f;
    font-size: 17px;
    margin-bottom: 0;
}

.live-streaming-post-user-details p {
    font-weight: 500;
    line-height: 1.5;
    color: #6f6f6f;
    font-size: 12px;
    margin-bottom: 0;
}

.live-streaming-post-action-icons{
    display: flex;
    margin-bottom: 0;
}

.live-streaming-post-action-icons li {
    margin-top: 0;
    margin-right: 1em;
    font-size: 14px;
    font-weight: 600;
}

.live-streaming-post-action-icons li i {
    font-size: 18px;
}

.live-streaming-post-action-icons li:last-child{
    margin-right: 0;
}

.live-streaming-post-box{
    grid-template-columns: repeat(3, 1fr);
    display: grid;
    grid-gap: 2rem;
}