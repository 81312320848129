.app-container {
    background-color: #f3f3f3;
    width: 100%;
    height: 100%;
    display: flex;
    transition: 0.2s;
    height: 100vh;
  }
  
  .left-side {
    position: relative;
    padding: 16px;
    flex-basis: 350px;
  }
  
  .navigation {
    display: flex;
    flex-direction: column;
    background-color: var(--navigation-bg);
    padding: 24px;
    border-radius: 10px;
    box-shadow: var(--navigation-box-shadow);
  }
  
  /* .nav-link + .nav-link {
    margin-top: 32px;
  } */
  
  .nav-link:hover svg {
    color: #3d42df;
  }
  
  .icon svg {
    width: 24px;
    height: 24px;
    color: var(--link-color);
    transition: 0.2s ease-in;
  }
  
  .right-side {
    margin-left: auto;
    flex-basis: 400px;
    height: 100%;
    padding: 16px;
    position: relative;
    transition: all 300ms cubic-bezier(0.19, 1, 0.56, 1);
  }
  /* .right-side.show {
    display: block;
    transform: translateX(0);
  } */
  
  .chat-container {
    background-color: var(--chat-background);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .chat-header-new {
    padding: 16px;
    border-bottom: 1px solid #f5f5f5;
    line-height: 16px;
    font-size: 15px;
    font-weight: 700;
    color: var(--message-text);
  }
  
  .chat-header-button {
    background-color: var(--main-color);
    padding: 12px 16px 12px 40px;
    border: none;
    border-radius: 4px;
    color: var(--secondary-color);
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 512 512' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M479.9 187.52l-90.19 68.53v-52.6a20 20 0 00-20-20H20a20 20 0 00-20 20V492a20 20 0 0020 20h349.71a20 20 0 0020-20v-52.6l90.18 68.52c13.05 9.91 32.1.67 32.1-15.92V203.45c0-16.5-18.94-25.92-32.1-15.93zM349.7 472H40V223.45h309.71zM472 451.68l-82.29-62.53V306.3L472 243.77zM87.96 79.24C129.62 28.88 190.86 0 256 0c65.13 0 126.37 28.88 168.03 79.24a20 20 0 01-30.82 25.5A177.6 177.6 0 00256 40a177.6 177.6 0 00-137.21 64.73 20 20 0 11-30.83-25.5zm240.36 32.21a20 20 0 11-21.02 34.03 97.57 97.57 0 00-51.3-14.53 97.6 97.6 0 00-51.31 14.53 20 20 0 11-21.02-34.03A137.53 137.53 0 01256 90.95c25.59 0 50.6 7.09 72.32 20.5zm0 0'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center left 12px;
    background-size: 16px;
    font-size: 14px;
  }
  
  .chat-area {
    flex: 1;
    overflow-y: auto;
    padding: 16px;
  }
  
  .profile-picture {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    overflow: hidden;
    margin-right: 12px;
    flex-shrink: 0;
  }
  .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .message-wrapper {
    display: flex;
  }
  
  .name {
    margin: 0;
    line-height: 16px;
    font-size: 12px;
    font-weight: 700;
    color: var(--message-text);
  }
  
  .message {
    margin-top: 8px;
    background-color: var(--message-bg);
    padding: 8px 16px;
    border-radius: 0 12px 12px 12px;
    font-size: 12px;
    line-height: 16px;
    /* max-width: calc(100% - 32px); */
    word-break: break-all;
    color: var(--message-text);
  }
  
  .message-wrapper {
    padding: 16px 0;
  }
  .message-wrapper.reverse {
    flex-direction: row-reverse;
  }
  .message-wrapper.reverse .message {
    background-color: var(--message-bg-2);
    color: var(--secondary-color);
    margin-left: auto;
    border-radius: 16px 0px 16px 16px;
  }
  .message-wrapper.reverse .profile-picture {
    margin-right: 0px;
    margin-left: 12px;
  }
  .message-wrapper.reverse .name {
    text-align: right;
  }
  
  .message-file {
    border: 1px solid var(--message-bg);
    width: 100%;
    margin-top: 16px;
    border-radius: 4px;
    padding: 8px;
    display: flex;
  }
  .message-file .sketch {
    border-radius: 4px;
    padding: 2px;
    background-color: #fdeee2;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
  }
  .message-file .sketch svg {
    width: 20px;
    height: 20px;
  }
  
  .file-info {
    flex: 1;
    padding: 0 40px 0 8px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%23b8b8b8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-download' viewBox='0 0 24 24'%3E%3Cpath d='M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3'/%3E%3C/svg%3E%0A");
    background-position: center right 12px;
    background-size: 20px;
    background-repeat: no-repeat;
    font-size: 12px;
  }
  
  .file-name {
    color: var(--message-text);
  }
  
  .file-size {
    color: #b8b8b8;
  }
  
  .mention {
    color: #7c80fd;
  }
  
  .chat-typing-area-wrapper {
    padding: 16px;
  }
  
  .chat-typing-area {
    display: flex;
    border-radius: 10px;
    padding: 8px;
    box-shadow: var(--navigation-box-shadow);
    background-color: var(--message-bg);
  }

  .product-search-area {
    display: flex;
    border-radius: 10px;
    padding: 8px;
    box-shadow: var(--navigation-box-shadow);
    background-color:var(--secondary-color);
    margin-top: 2em;
  }
  
  .dark .chat-typing-area {
    box-shadow: none;
  }
  
  .chat-input {
    border: none!important;
    font-size: 14px!important;
    line-height: 24px!important;
    height: auto!important;
    outline: none;
    color: var(--message-text);
    flex: 1;
    background-color: transparent!important;
    padding: 0!important;
    margin:0;
  }

  .chat-input:focus{
    border: none!important;
  }

  .chat-input:placeholder {
    color: var(--placeholder-text);
  }


  .product-search-input {
    border: none!important;
    font-size: 14px!important;
    height: auto!important;
    line-height: 24px!important;
    outline: none;
    color: var(--message-text);
    flex: 1;
    background-color: transparent;
    padding: 0!important;
    margin: 0;
  }
  .product-search-input:placeholder {
    color: var(--placeholder-text);
  }

  .product-search-input:focus{
    border: none!important;
  }
  
  
  .send-button {
    color: var(--secondary-color);
    background-color: var(--primary-color);
    border-radius: 8px;
    border: none;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* font-size: 13px; */
  }
  .send-button svg {
    width: 20px;
    height: 20px;
    color: #000000;
  }
  
  .app-main {
    flex: 1;
    width: 100%;
    padding: 16px 16px 16px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .video-call-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }
  
  .video-participant {
    width: 100%;
    height: 100%;
    position: relative;
  }
  .video-participant img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .name-tag {
    position: absolute;
    font-size: 12px;
    color: var(--secondary-color);
    background-color: rgba(0, 15, 47, 0.5);
    border-radius: 4px;
    padding: 4px 12px;
    z-index: 9;
    top: 12px;
    left: 12px;
  }
  
  .participant-actions {
    position: absolute;
    display: flex;
    right: 12px;
    top: 12px;
    z-index: 9;
  }

  .participant-notify{
      display: flex;
    }
  
.participant-notify li {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #D0342C;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 16px;
    border: none;
    margin-top: 0;
    padding: 4px 15px;
    font-size: 12px;
    color: var(--secondary-color);
    font-weight: 500;
  }

  .participant-notify li:last-child{
    background-color: rgba(0, 15, 47, 0.5);
  }

  .participant-notify li:nth-child(2){
    background-color: rgba(0, 15, 47, 0.5);
    margin-right: 0.8em;
  }

  .participant-notify li:first-child{
      margin-right: 0.8em;
  }
  
  .video-call-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 64px;
    max-width: 500px;
  }
  
  .video-action-button {
    background-repeat: no-repeat;
    background-size: 24px;
    border: none;
    height: 48px;
    margin: 0 8px;
    box-shadow: var(--navigation-box-shadow);
    border-radius: 8px;
    width: 48px;
    cursor: pointer;
    outline: none;
    background-color: var(--button-bg);
  }
  .video-action-button.mic {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mic-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M9 9v3a3 3 0 005.12 2.12M15 9.34V4a3 3 0 00-5.94-.6'/%3E%3Cpath d='M17 16.95A7 7 0 015 12v-2m14 0v2a7 7 0 01-.11 1.23M12 19v4M8 23h8'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
  .video-action-button.camera {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-camera-off' viewBox='0 0 24 24'%3E%3Cpath d='M1 1l22 22M21 21H3a2 2 0 01-2-2V8a2 2 0 012-2h3m3-3h6l2 3h4a2 2 0 012 2v9.34m-7.72-2.06a4 4 0 11-5.56-5.56'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
  .video-action-button.maximize {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' stroke='%232c303a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-maximize' viewBox='0 0 24 24'%3E%3Cpath d='M8 3H5a2 2 0 00-2 2v3m18 0V5a2 2 0 00-2-2h-3m0 18h3a2 2 0 002-2v-3M3 16v3a2 2 0 002 2h3'/%3E%3C/svg%3E%0A");
    background-position: center;
  }
  .video-action-button.endcall {
    color: #ff1932;
    width: auto;
    font-size: 14px;
    padding-left: 42px;
    padding-right: 12px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff1932' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone-missed'%3E%3Cline x1='23' y1='1' x2='17' y2='7'/%3E%3Cline x1='17' y1='1' x2='23' y2='7'/%3E%3Cpath d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'/%3E%3C/svg%3E");
    background-size: 20px;
    background-position: center left 12px;
  }
  .video-action-button.magnifier {
    padding: 0 12px;
    display: flex;
    align-items: center;
    width: auto;
    flex-grow: 0;
    color: #2c303a;
  }
  .video-action-button.magnifier svg {
    width: 20px;
    flex-shrink: 0;
  }
  .video-action-button.magnifier span {
    display: block;
    margin: 0 16px;
  }
  
  .participants {
    display: flex;
    background-color: var(--button-bg);
    box-shadow: var(--navigation-box-shadow);
    margin-top: 16px;
    padding: 12px;
    border-radius: 8px;
    max-width: 232px;
    margin-left: auto;
  }
  
  .participant-more {
    background-color: #e1e0e1;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #636181;
    font-weight: 700;
    border-radius: 8px;
    min-width: 32px;
  }
  
  .mode-switch {
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 36px;
    background-color: var(--chat-background);
    border: none;
    color: #ddd;
    outline: none;
    cursor: pointer;
    box-shadow: var(--navigation-box-shadow);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    transform-origin: center;
  }
  .mode-switch svg {
    width: 0;
    height: 24px;
    transition: all 0.3s ease-in;
    transform-origin: center;
  }
  .mode-switch .moon {
    opacity: 0;
  }
  .mode-switch .sun {
    width: 24px;
  }
  
  .dark .moon {
    opacity: 1;
    width: 24px;
  }
  
  .dark .sun {
    opacity: 0;
    width: 0;
  }
  
  .btn-close-right {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 24px;
    right: 24px;
    color: var(--light-font);
    outline: none;
    cursor: pointer;
    display: none;
  }
  
  @media screen and (max-width: 900px) {
    /* .right-side {
      position: absolute;
      right: 0px;
      background-color: var(--chat-background);
      transform: translateX(100%);
      z-index: 2;
      width: 100%;
    }
    .right-side.show {
      transform: translateX(0);
    } */
  
    .btn-close-right {
      display: block;
    }
  }
  @media screen and (max-width: 520px) {  
    .app-main {
      padding: 16px 16px 16px 16px;
    }
  
    .expand-btn {
      right: 16px;
    }
  
    .mode-switch {
      left: 16px;
    }
  
    .video-call-actions {
      padding-top: 32px;
    }
  
    .video-action-button {
      width: 32px;
      height: 32px;
      background-size: 14px;
      margin: 0 4px;
    }
  
    .video-action-button.endcall {
      background-size: 14px;
      font-size: 12px;
      padding-left: 36px;
    }
  
    .video-action-button.magnifier svg {
      width: 14px;
    }
  
    .video-action-button.magnifier span {
      margin: 0 4px;
      font-size: 12px;
    }
  }

  .video-call-product-list-sec .nav-link{
    font-size: 12px;
    font-weight: 600;
    color: var(--senary-color)!important;
    background-color: var(--secondary-color);
  }
  
  .video-call-product-list-sec .nav-link.active {
    background-color: var(--primary-color)!important;
    color: var(--senary-color)!important;
  }

  .video-call-product-list-sec .nav-item{
    margin-right: 10px;
  }

  .video-call-product-list-sec .nav-item:last-child{
      margin-right: 0;
  }

  .video-product-list-box{
    overflow-y: auto;
    height: 76vh;
    margin-top: 2em
  }

  .video-product-list-card {
    display: flex;
    border-radius: 10px;
    padding: 10px;
    box-shadow: var(--navigation-box-shadow);
    background-color: var(--secondary-color);
    margin-bottom: 2em;
    align-items:center;
    justify-content: space-between;
  }
/* 
  .video-product-list-card:nth-child(1){
    margin-top: 2em;
  } */

  .video-product-list-left-sec{
      width: 72%;
  }

  .video-product-list-right-sec{
    width: 35%;
    text-align: right;
  }

  .purchased .video-product-list-left-sec{
    width: 75%;
  }

  .purchased .video-product-list-right-sec{
    width: 25%;
  }

  .video-product-list-left-sec h4{
    font-size: 1.4em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
  }

  .video-product-list-left-sec h6{
    font-size: 1.2em;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
  }

  .video-product-list-left-sec p {
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 0;
  }

  .video-product-list-right-sec h4{
    font-size: 1.3em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
  }

  .video-product-list-right-sec p{
    font-size: 1.1em;
    font-weight: 400;
    margin-bottom: 0;
  }

  .app-container ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

.app-container ::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.app-container ::-webkit-scrollbar-thumb
{
	background-color: #F90;	
	background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent)
}

.video-product-list-left-sec .sold-color{
    font-size: 1.3em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
    color: #04AA6D;
}

.purchased .video-product-list-left-sec .sold-color{
    font-size: 1.1em;
    font-weight: 700;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: 4em;
    color: #04AA6D;
}

/*Create Video Call CSS*/

/* .create-video-call-latest-sec{
  margin-top: 48px;
} */

/* .create-video-call-latest-box{
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  height: 100vh;
} */

.collapse.show{
  visibility: visible;
}

.create-video-call-left-form-sec{
  background-color: var(--secondary-color);
  padding: 50px 0px;
}

.create-video-call-left-form-sec h4{
  font-size: 3.0rem;
  color: #1c2437;
  font-weight: 700;
  margin-bottom: 10px;
}

.create-video-call-left-form-sec p {
  font-size: 1.5rem;
  color: #546274;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 35px;
}

.create-video-call-right-sec{
  /* background-color: #f0f2f4; */
  padding: 75px 0px;
}

.create-video-img{
  width: 100%;
}

.create-video-call-form .upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: block;
}

.create-video-call-form .btn {
  height: 75px!important;
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  outline: none!important;
  box-shadow: none;
  padding: 20px 20px 20px!important;
  font-size: 1.3rem!important;
  border-radius: 15px!important;
  color: #546274;
  background-color: transparent!important;
  width: 100%;
  text-align: left;
}

.create-video-call-form .upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.create-video-call-form .height-auto.form-control {
  height: 90px!important;
  padding: 35px 20px 0!important;
}

.create-video-call-form .form-control {
  height: 75px!important;
  border: 1px solid rgba(0, 0, 0, 0.1)!important;
  outline: none!important;
  box-shadow: none;
  padding: 20px 20px 0!important;
  font-size: 1.3rem!important;
  border-radius: 15px!important;
  color: #546274;
}

.create-video-call-form .form-control::placeholder{
  font-size: 1em;
  font-weight: 500;
}

.create-video-call-form .form-group{
  position: relative;
}

.create-video-call-form .form-group label {
  position: absolute;
  top: 12px;
  left: 21px;
  font-size: 1.4rem;
  font-weight: 500;
  -webkit-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
  color: #1c2437;
  z-index: 1;
}

.create-video-call-form .form-group .form-control::placeholder {
  color: #546274;
}

.create-video-call-right-sec .slick-dots li.slick-active button:before{
  background-color: transparent;
}

.create-video-call-right-sec .slick-dots li.slick-active button:before{
  color: var(--senary-color);
}

.create-video-call-right-sec .slick-dots li button:before{
  font-size: 18px;
}

.create-video-call-btn{
  background: -webkit-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: -o-linear-gradient(110deg, #f794a4 0%, #fdd6bd 100%);
  background: linear-gradient(
      -20deg, #fff351 0%, #fff351 100%);
  border: none;
  color: var(--senary-color);
  display: inline-block;
  border-radius: 1000px;
  font-weight: 700;
  font-size: 15px;
  padding: 1em 2em;
  margin-top: 1.5em;
}

.create-video-call-right-sec .slick-dots{
  bottom: -100px;
}

.buy-now-badge{
  font-size: 1.1em;
  background: #fff351;
  padding: 5px;
  margin-bottom: 0.5em;
  display: inline-block;
  color: var(--senary-color)!important;
  font-weight: 600;
  border-radius: 3px;
}

.upload-img-preview{
  height: 15em;
  object-fit: cover;
  width: 20em;
  border-radius: 10px;
}

.upload-img-preview-sec{
  margin-bottom: 1em;
}

.product-check-box-sec ul {
      list-style-type: none;
        grid-template-columns: repeat(2, 1fr);
        display: grid;
  
}

.product-check-box-sec{
  padding-top: 1em;
}

.choose-multiple-product-sec .btn-primary:active,
.choose-multiple-product-sec .btn-primary:focus{
  background-color: transparent!important;
}

.choose-multiple-product-sec .btn-primary:not(:disabled):not(.disabled):active{
  background-color: transparent!important;
}

.product-check-box-sec li {
  /* grid-template-columns: repeat(1, 1fr);
  display: grid;
  margin-top: 0; */
}

.product-check-box-sec input[type="checkbox"][id^="myCheckbox"] {
  display: none;
}

.product-check-box-sec label {
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  position: relative;
  margin: 10px;
  cursor: pointer;
  padding: 1em;
}

.products-info{
  padding-left: 1em;
}

.product-check-box-sec label:before {
  background-color: var(--secondary-color);
  color: var(--secondary-color);
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  transition-duration: 0.4s;
  transform: scale(0);
}

.product-check-box-sec label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
  border-radius: 10px;
}

.product-check-box-sec :checked + label {
  border-color: #ddd;
  border-radius: 15px;
}

.product-check-box-sec :checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

.product-check-box-sec :checked + label img {
  transform: scale(0.9);
  /* box-shadow: 0 0 5px #333; */
}

.products-info p{
  color: #666;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
  /* width: 270px; */
  display: block;
}

.products-info h5{
  font-weight: 700;
  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 4em;
  /* width: 270px; */
  display: block;
}
.streaming-type-sec{
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  padding: 1.8em;
  border-radius: 15px;
}
.streaming-type-sec .two-radio-btn{
  display: flex;
  gap: 2em;
}
.product-check-box-sec ul li {
  margin-top: 0;
}