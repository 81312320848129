
.header-nav-center {
    padding: 1em 0;
    border-bottom: 0;
    -webkit-transition: 0.3s !important;
    -moz-transition: 0.3s !important;
    -o-transition: 0.3s !important;
    -ms-transition: 0.3s !important;
    transition: 0.3s !important;
    background-color: transparent;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    height: auto;
    box-shadow: none;
}

.header-nav-center .navbar .nav-item:first-child{
    margin-right: 1.2em;
}

.header-nav-center .navbar .nav-item .nav-link {
    font-size: 1.4em;
    font-weight: 600;
    text-transform: capitalize;
    color: var(--secondary-color);
    opacity: 0.8;
    letter-spacing: 0.5px;
}

.header-nav-center .navbar .nav-item .nav-link:hover {
    color: var(--secondary-color);
    opacity: 1;
}

.header-nav-center .navbar .nav-item .nav-link.active {
    color: var(--secondary-color);
    background-color: transparent;
    opacity: 1;
}

/* .header-nav-center .navbar {
    padding: 0rem 0rem !important;
} */

.header-nav-center .navbar-brand{
    height: auto;
    padding-left: 0;
}

.header-nav-center .navbar-collaps{
    padding-left: 0;
}

.latest-header-form {
    display: flex;
    align-items: center;
    margin-right: 1.2em;
}

.header-fixed {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    background-color: #FFB72B;
    -webkit-backface-visibility: hidden;
    height: 80px;
}

.header-fixed .navbar .nav-item .nav-link {
    color: var(--secondary-color);
    opacity: 0.8;
}

.latest-header-btn{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    padding:1em 2em!important;
    border-radius: 50rem!important;
    color: #000!important;
    opacity: 1!important;
    text-align: center;
}

.latest-header-btn-outline{
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
    padding:1em 2em!important;
    border-radius: 50rem!important;
    color: #000!important;
    opacity: 1!important;
    text-align: center;
}

.latest-header-btn-outline:hover{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
}

.latest-header-btn:hover{
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    background-color: var(--secondary-color);
    border-color: var(--secondary-color);
}

.latest-header-form .input-group{
    background-color: #fff;
    border-radius: 50px;
    width: 220px;
}

.latest-header-form .input-group .form-control{
    padding: 12px 20px!important;
    height: 40px!important;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: transparent;
    border: 0!important;
    color: #444;
}

.latest-header-form .input-group .form-control:focus,
.latest-header-form .input-group .form-control:hover,
.latest-header-form .input-group .form-control:active{
    border: 0!important;
}

.latest-header-form .input-group .input-group-text {
    background-color: transparent;
    border: 0;
    padding: 10px 15px;
    font-size: 15px;
}

.latest-header-form .input-group .input-group-text i{
    color: #DDD;
}

.home-banner-sec {
    background-image: url('./banner-img.jpg');
    min-height: 100vh;
    /* padding-bottom: 4em; */
    background-repeat: no-repeat;
    background-position:bottom;
    background-size: cover;
    position: relative;
    width: 100%;
    display: block;
}

.home-banner-sec:before{
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
}

.new-landing-wrapper .banner-content {
    padding-top: 0em!important;
    padding-bottom: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

.banner-info-sec {
    max-width: 558px;
    /* margin: auto;
    text-align: center; */
    padding-top: 5em;
}

.banner-right-img {
    width: 100%;
    margin-top: 4em;
}

.new-landing-wrapper .banner-content h2 {
    font-size: 5rem;
    font-weight: 800;
    line-height: 1.2;
    color: var(--secondary-color);
}

.new-landing-wrapper .banner-content h1 span {
    font-weight: 900;
}

.new-landing-wrapper .banner-content p {
    font-size: 1.4em;
    color: var(--secondary-color);
    padding: 1em 0;
    line-height: 1.8;
    font-weight: 400;
}

.landing-logo{
    max-width: 190px;
}

.footer-landing-logo{
    max-width: 200px;
}

.btn.banner-theme-btn{
    display: inline-block;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    padding:0.5em 1.5em!important;
    border-radius: 50rem!important;
    color: #000!important;
    opacity: 1!important;
    text-align: center;
    font-size: 1.5em;
    margin-right: 1em;
    font-weight: 700;
}

.btn.banner-theme-outline-btn {
    display: inline-block;
    border:2px solid var(--primary-color)!important;
    background-color: transparent;
    border-color: transparent;
    padding:0.5em 1.5em!important;
    border-radius: 50rem!important;
    color: #000!important;
    opacity: 1!important;
    text-align: center;
    font-size: 1.5em;
    font-weight: 700;
}

.btn.btn-get-started{
    display: inline-block;
    border:2px solid var(--primary-color)!important;
    background-color: transparent;
    border-color: transparent;
    padding:0.5em 1.5em!important;
    border-radius: 50rem!important;
    color: var(--primary-color)!important;
    opacity: 1!important;
    text-align: center;
    font-size: 1.8em;
}

.new-landing-wrapper .sm-padding{
    padding:8em 0;
}

.new-landing-wrapper .margin-top-lg{
    margin-top: 4em;
}

.new-landing-wrapper .margin-top-xl{
    margin-top: 7em;
}

.latest-section-title{
    text-align: center;
}

.latest-section-title h3{
    font-size: 4em;
    font-weight: 700;
}

.latest-section-title p{
    color: #5f5f5f;
    font-size: 1.4em;
    line-height: 1.8;
    font-weight: 400;
}

.product-category-box{
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    display: grid;
}

.product-category-img-1{
    width:100%;
}

.product-category-img{
    width: 100%;
    height: 10em;
    object-fit: contain;
}

.product-category-card{
    padding: 1.5em;
    background-color: var(--secondary-color);
}

.product-category-sec{
    background-color: rgb(245, 244, 249);
}

.product-category-info{
    padding-top: 1.5em;
    text-align: center;
}

.product-category-info h4{
    font-size: 1.1em;
    font-weight: 700;
    color: #18113c;
}

.product-category-info p {
    color: #5c5776;
    font-size: 0.9em;
    font-weight: 600;
}

.product-category-info p span{
    padding-left: 0.4em;
}


/*Product Featured CSS*/

.whatnot-featured-card {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 30px 0;
    max-width: 360px;
}

.whatnot-featured-card .wahtnot-featured-icon-sec{
    background: #f8c301;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 50px;
    min-width: 50px;
    border-radius: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: 25px;
    position: relative;
    z-index: 1;
}

.whatnot-featured-card .wahtnot-featured-icon-sec::before {
    position: absolute;
    content: "";
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    border: 1px solid rgba(248, 195, 1, 0.6);
    border-radius: 50%;
    bottom: 0;
    right: 0;
}

.whatnot-featured-info h4{
    font-size: 2.5em;
    font-weight: 700;
    color: #18113c;
}

.whatnot-featured-info p {
    color: #5c5776;
    font-size: 1.3em;
    font-weight: 400;
}

.whatnot-featured-card .wahtnot-featured-icon{
    width: 3em;
}

.whatnot-footer-sec{
    background-color: #212529;
    padding: 8em 0;
}

.whatnot-footer-social-link-sec{
    display: flex;
    align-items: center;
    gap: 1em;
}

.whatnot-footer-social-link-sec li{
    width: 35px;
    height: 35px;
    line-height: 1.7;
    position: relative;
    z-index: 1;
    vertical-align: middle;
    display: flex;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-border-radius: 50%;
    border-radius: 5px;
    padding: 0;
    cursor: pointer;
    background: rgba(255, 255, 255, 0.05);
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 0;
}

.whatnot-footer-social-link-sec li i{
   color: #fff;
}

.whatnot-footer-content .what-not-footer-logo{
    max-width: 200px;
}

.whatnot-footer-content p{
    margin-bottom: 0;
    font-size: 15px;
    padding: 2em 0;
    color: rgba(255, 255, 255, 0.4);
}

.whatnot-footer-content h3{
    color: #fff;
    font-size: 2em;
    font-weight: 700;
    padding-bottom: 0.5em;
}

.whatnot-footer-widget li a {
    font-size: 15px;
    color: rgba(255, 255, 255, 0.4);
    line-height:1.8;
}

.sub-footer p{
    font-size: 15px;
    color: #fff;
    margin-bottom: 0;
    padding-bottom: 0;
}

.live-streaming-notify-img{
    width: 100%;
}


.whatnot-livestreaming-img-sec{
    position: relative;
}

.whatnot-livestreaming-img-sec:after{
    content: "\f0e7";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 0;
    top: 0;
    color: #ffffff;
    font-weight: 600;
    background-color: #f85c70;
    width: 5em;
    height: 5em;
    padding:1em;
    font-size: 1em;
    line-height: 1.4;    
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.whatnot-livestreaming-img {
    width: 100%;
    height: 14em;
    object-fit: cover;
}

.whatnot-livestreaming-card {
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding:1.5em;
    border-radius: 8px;
    margin:10px;
    margin-top: 5em;
}

.whatnot-livestreaming-info{
    padding-top: 1.5em;
}

.whatnot-livestreaming-info .whatnot-livestreaming-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.whatnot-live-user-info h4{
    font-size: 1.1em;
    font-weight: 700;
    color: #18113c;
    font-family: 'Gilroy', sans-serif!important;   
}

.whatnot-live-user-info p{
    color: #5c5776!important;
    font-size: 0.9em!important;
    font-weight: 400;
    padding: 0!important;
}

.whatnot-live-user-card{
    display: flex;
    gap: 1em;
}

.whatnot-hash-tag{
    padding-left: 3.4em;
}

.category-base-live-streaming-box{
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    display: grid;
}

.category-base-live-streaming-img-sec{
    position: relative;
}

.category-base-live-streaming-img-sec:after{
    content: "\f0e7";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 0;
    top: 0;
    color: #ffffff;
    font-weight: 600;
    background-color: #f85c70;
    width: 5em;
    height: 5em;
    padding:1em;
    font-size: 1em;
    line-height: 1.4;    
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.category-base-live-streaming-img {
    width: 100%;
    height: 14em;
    object-fit: cover;
}

.category-base-live-streaming-card {
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    /* padding:2em; */
    border-radius: 8px;
    margin:10px;
}

.category-base-live-streaming-info{
    padding-top: 1.5em;
}

.category-base-live-streaming-info .whatnot-live-user-img-sec .category-base-live-streaming-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}


.ongoing-live-streaming-box{
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
    display: grid;
}

.ongoing-live-streaming-img-sec{
    position: relative;
}

.ongoing-live-streaming-img-sec:after{
    content: "\f0e7";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    left: 0;
    top: 0;
    color: #ffffff;
    font-weight: 600;
    background-color: #f85c70;
    width: 5em;
    height: 5em;
    padding:1em;
    font-size: 1em;
    line-height: 1.4;    
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 100%);
}

.ongoing-live-streaming-img {
    width: 100%;
    height: 14em;
    object-fit: cover;
}

.ongoing-live-streaming-card {
    background-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    padding:1.5em;
    border-radius: 8px;
    margin:10px;
}

.ongoing-live-streaming-info{
    padding-top: 1.5em;
}

.ongoing-live-streaming-info .whatnot-live-user-img-sec .ongoing-live-streaming-icon{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.ongoing-live-streaming-sec {
    background-color: rgb(245, 244, 249);
}

.show-all-btn{
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    padding:0.6em 2em!important;
    border-radius: 50rem!important;
    color: #000!important;
    opacity: 1!important;
    text-align: center;
    font-weight: 700;
    display: inline-block;
}

.show-all-sec{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 2em;
}
